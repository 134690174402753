import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import {Heading, HorizontalDivider, Footer } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
// import HomeHero from "../sections/home/HomeHero";
import VisionStatement from "../sections/about-us/VisionStatement";
import MissionStatement from "../sections/about-us/MissionStatement";
import AboutUsCommunity from "../sections/about-us/AboutUsCommunity";
import AboutUsContact from "../sections/about-us/AboutUsContact";
import AboutUsLogos from "../sections/about-us/AboutUsLogos";

// TODO: #27 AboutUs - mission
// TODO: #29 AboutUs - values
// TODO: #30 AboutUs - community

const AboutUs = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='About Us'
    >
      {/* <Announcement 
        containerBackgroundColor='var(--bf-core-teal)'
        textColor='white'
        // copy={data.homeAnnouncement.data.header}
      /> */}
      {/* <HomeHero /> */}
      <MissionStatement />
      <HorizontalDivider />
      <VisionStatement />
      <HorizontalDivider />
      {/* <Heading h2> Our values </Heading> */}
      {/* <HorizontalDivider /> */}
      <AboutUsCommunity />
      <AboutUsLogos />
      <AboutUsContact />
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query AboutUsPageContentQuery {
    
//   }
// `

export default AboutUs;