import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleRowLogos, SupportersFunders } from '@bestfit-team/bf-base-ui';

const AboutUsLogos = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query AboutUsLogosQuery {
        myAboutUsLogos: allAirtableAllImagesTable(
          filter: {data: {page__from_content_: {eq: "About Us"}
          # , Content: {eq: "recEPfsMaRqjw8i63"}
          }
          }
      sort: {fields: data___section_order}
        ) {
          nodes {
            data {
              file_name
              redirect_url
              aws_url
              attachments {
                filename
              }
            }
          }
        }
    }
  `);

console.log(JSON.stringify(copy))

  const holdSupportersFundersData = copy.myAboutUsLogos.nodes;
  // console.log("REMOVED: " + JSON.stringify(holdBestFitUsersData));


  return (
      <SupportersFunders
        version={'Companies'}
        heroBackgroundColor={'var(--bf-core-teal)'}
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={
          'You’re in good company'
        }
        subheadingCopy={
          'Our funders and supporters'
        }
        imageData={
          holdSupportersFundersData
        }
      />
  )
}

export default AboutUsLogos;
