import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { OurCommunity } from '@bestfit-team/bf-base-ui';

const AboutUsCommunity = () => {
  const copy = useStaticQuery(graphql`
    query MyQuery {
      communityContent: airtable(data: {Segment: {eq: "Community"}}) {
        data {
          header
          subheader
          subheader_2
          status
          Images {
            data {
              attachments {
                filename
                url
              }
              alt_text
              redirect_url
              section_order
            }
          }
        }
      }
    }
  `);
  
  return (
    <OurCommunity
      headingCopy={copy.communityContent.data.header || 'Our community'}
      subheadingCopy={copy.communityContent.data.subheader}
      imagesArray={copy.communityContent.data.Images}
    />
  )
}

export default AboutUsCommunity