import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { OurMission } from '@bestfit-team/bf-base-ui';

const MissionStatement = () => {
  const copy = useStaticQuery(graphql`
    query MissionStatementQuery {
      missionCopy: airtable(
        data: {Segment: {eq: "Mission"}}, 
        ) {
          data {
            header
            subheader
            subheader_2
            status
            Images {
              data {
                attachments {
                  filename
                  url
                }
                alt_text
                redirect_url
                section_order
                caption
                header
              }
            }
          }
      }
      
    #   missionImages: allAirtableAllImagesTable(filter: {data: {Content: {eq: "reclVnaduURT78381"}}}) {
    #     nodes {
    #       data {
    #         attachments {
    #           url
    #           filename
    #         }
    #         alt_text
    #         header
    #         caption
    #         redirect_url
    #       }
    #     }
    # }
  }
`);

console.log(JSON.stringify(copy))
  return (
    <OurMission
      headingCopy={copy.missionCopy.data.header}
      subheadingCopy={copy.missionCopy.data.subheader}
      bodyCopy={copy.missionCopy.data.subheader_2}
      heroImageUrl={copy.missionCopy.data.Images[0].data.attachments[0].url}
      captionHeader={copy.missionCopy.data.Images[0].data.header}
      captionBody={copy.missionCopy.data.Images[0].data.caption}
      heroImageAlt={copy.missionCopy.data.Images[0].data.alt_text}
    />
  )
}

export default MissionStatement;
